@import '../../style/utils.scss';

.menu {
    width: 100%;
    height: 83px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    z-index: 20;
    top: 0;
    bottom: 0;
    padding: 20px 0;
    transition: all 200ms ease-in-out;

    @include tablet {
        justify-content: space-between;
        height: 53px;
    }

    @include mobile {
        height: 48px;
    }

    > button {
        opacity: 1;
        min-width: 165px;
        color: black;

        @include tablet {
            background-color: lighten($color-primary, 50%);
            border-radius: 0 0 15px 15px;
            @include shadow;
            margin-left: 10px;
        }

        &[animation='1'] {
            @include mobile {
                transform: translateX(-1000px) scale(0);
                transition: all 1s;
            }
        }

        &[animation='0'] {
            @include mobile {
                transform: translateX(0) scale(1);
                transition: transform 800ms, opacity 150ms;
            }
        }

        &:hover, &:focus {
            opacity: 0.7;
            transition: all 150ms;
        }
    }

    h1 {
        font-family: 'Lobster Two', 'Brush Script MT';
        font-weight: 400;
        margin: 0;

        @include tablet {
            padding: 15px;
        }

        @include mobile {
            padding: 10px;
        }
    }

    &__nav {
        display: flex;
        border-bottom: 1px rgba(0, 0, 0, 0.44) solid;
        transition: all 200ms 400ms ease-in-out;

        @include tablet {
            border: none;
        }

        li {
            margin: 0 20px;
            text-transform: uppercase;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.8);
            transform: scale(1);
            opacity: 1;
            transition: all 200ms, padding 200ms 400ms ease-in-out;
            padding: 30px 0;

            @include tablet {
                margin: 0 6px;
                position: relative;
                right: 85px;
                top: 4px;
            }

            @include mobile {
                margin: 0 3px;
                right: 155px;
            }

            &:hover, &:focus-within {
                transform: scale(1.1);
                opacity: 0.7;
                transition: transform 200ms, opacity 200ms;
                @include tablet {
                    transform: scale(1);
                }
            }

            a {
                padding: 30px 0;
            }

            a.active {
                color: lighten($color-secondary, 12%);
            }
        }

        &[animation='1'] {
            @include tablet {
                animation: openMenu 400ms ease-in-out both;
            }
        }

        &[animation='0'] {
            @include tablet {   
                animation: closeMenu 400ms ease-in-out both;
            }
        }
    }

    &.scroll {
        background-color: white;
        padding: 5px 0;
        height: 53px;
        border-bottom: 1px rgba(0, 0, 0, 0.121) solid;
        transition: all 400ms ease-in-out;

        @include tablet {
            background-color: transparent;
            border: none;
            opacity: 1;
        }

        @include mobile {
            height: 48px;
        }
    }
    
    &.scroll &__nav {
        border: none;

        li {
            padding: 5px 0;

            &:hover, &:focus-within {
                transform: scale(1);
                padding: 5px 0 2px 0;
                border-bottom: 3px solid $color-primary;
                @include tablet {
                    border: none;
                    padding: 5px 0;
                }
            }

            a {
                padding: 15px 0;
            }
        }
    }
}

.burger {
    display: none;
    position: fixed;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
    background-color: lighten($color-primary, 50%);
    border-radius: 50%;
    padding: 80px;
    z-index: 11;
    @include shadow;

    &:hover &__LineMiddle, &:focus &__LineMiddle {
        background-color: lighten($color-secondary, 20%);
    }

    @include tablet {
        display: block;
    }

    @include mobile {
        padding: 60px;
    }

    &__Line {
        position: absolute;
        top: 68%;
        right: 58%;
        width: 34px;
        height: 3px;
        border-radius: 3px;
        background-color: black;
        transition: all 0.3s ease;

        @include mobile {
            width: 28px;
            height: 2px;
        }

        &Top {
            transform: translateY(-8px);
        }
    
        &Bottom {
            transform: translateY(8px);
        }
    }

    &[animation='1'] &__LineTop {
        transform: translate(0, 0) rotate(45deg);
    }

    &[animation='1'] &__LineMiddle {
        opacity: 0;
    }

    &[animation='1'] &__LineBottom {
        transform: translate(0, 0) rotate(-45deg);
    }
}

.icone__mob {
    display: none;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 15px;
    border-radius: 50%;

    @include tablet {
        display: block;
    }

    @include mobile {
        padding: 10px;
    }
}

@include tablet {
    .hideMenu {
        display: none;
    }

    .hideDesktop {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
    }
}


@keyframes openMenu {
    0% {
        transform: translateY(-100px);
        opacity: 0; 
    }
    100% {
        transform: translateY(0);
        opacity: 1; 
    }
 
}

@keyframes closeMenu {
    0% {
        transform: translateY(0);
        opacity: 1; 
    }
    100% {
        transform: translateY(-100px);
        opacity: 0; 
    }
 
}