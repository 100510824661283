// Variables
$color-primary: rgb(248, 233, 212);
$color-secondary: rgb(69, 107, 78);

@mixin shadow {
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.284);
}

// Breakpoints
@mixin mobile {
    @media (max-width: 576px) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: 992px) {
        @content;
    }
}