@import '../../style/utils.scss';

.angle {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 16vh;
    background-color: $color-primary;
    transform: skewY(-2deg) translateY(8vh);
}

.section {
    background-color: $color-secondary;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(1px);

    &__bloc {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transform: translateY(-4vh);
        padding: calc(4vh + 55px) 10px;
        opacity: 0;

        &[animation='1'] {
            opacity: 1;
            transition: opacity 500ms 100ms ease-in-out;
        }
    }

    &__title {
        color: white;
        width: 70%;
        max-width: 855px;

        @include tablet {
            width: 80%;
        }

        @include mobile {
            width: 100%;
        }

            
        &Line {
            height: 1px;
            background-color: white;
            width: 150px;
            margin: 35px auto 55px auto;

            @include mobile {
                margin: 20px auto 35px auto;
            }
        }

        h2 {
            font-family: 'Lobster Two', 'Brush Script MT';
            font-size: 3em;
            text-align: center;
            font-weight: 500;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 40px 0;
            position: relative;

            @include mobile {
                margin: 20px 0;
            }

            span {
                text-transform: uppercase;
                opacity: 0.1;
                font-size: 1.7em;
                position: absolute;

                @include tablet {
                    font-size: 1.5em;
                }
            }
        }
    }

    &__container {
        display: flex;
        max-width: 1300px;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 40px;

        @for $i from 1 through 5 {
            &[animation='1'] > div:nth-child(#{$i}) {
                animation: chargement 500ms ease-in-out backwards;
                animation-delay: $i * 100ms + 200ms;
            }
        }

        @include tablet {
            gap: 30px;
            flex-direction: column;
        }

        @include mobile {
            gap: 20px;
        }

        &Work {
            width: 40%;
            max-width: 600px;
            transform: scale(1);
            transition: transform 200ms;
            border-radius: 10px;
            position: relative;
            overflow: hidden;
            display: flex;
            @include shadow;

            @include tablet {
                width: 80%;
                flex-direction: column;
                max-width: none;
            }

            @include mobile {
                width: 100%;
            }

            &:hover, &:focus-within {
                transform: scale(1.1);
                transition: transform 200ms;
                cursor: inherit;

                @include tablet {
                    transform: scale(1);
                }

                > div {
                    display: flex;
                    animation: apparition 800ms;

                    @include tablet {
                        animation: none;
                    }
                }
            }
            
            img {
                width: 100%;
                height: 100%;
            }

            > div {
                position: absolute;
                right: 0;
                top: 0;
                background-color: rgb(255, 255, 255);
                height: 100%;
                width: 100%;
                display: none;
                justify-content: space-between;
                align-items: center;
                gap: 20px;
                padding: 20px;

                @include tablet {
                    display: flex;
                    position: relative;
                }

                p {
                    margin: 2px 0;
                    font-size: 0.8em;
                    text-align: justify;
                    font-weight: 400;

                    @include tablet {
                        font-size: 1em;
                    }

                    @include mobile {
                        margin: 5px 0;
                    }

                    em {
                        font-weight: 600;
                        font-style: normal;
                        color: $color-secondary;
                    }
                }
            }
            
            &Flex {
                display: flex;
                height: 100%;
                flex-direction: column;
                justify-content: center;
                gap: 30px;
                align-items: center;
                margin: 0 5px;

                a {
                    opacity: 1;
                    transition: all 100ms;

                    &:hover, &:focus {
                        opacity: 0.7;
                        transition: all 150ms;
                    }
                }
            }
        }
    }

    @include tablet {
        font-size: 0.9em;
    }

    @include mobile {
        font-size: 0.8em;
    }
}

@keyframes apparition {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes chargement {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}