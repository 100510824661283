@import '../../style/utils.scss';

/* Barre de progression */
.container {
    margin: 5px 0 20px 0;
    height: 20px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.303);
}

/* Contenu de la barre de progression */
.progress {
    animation: progress 500ms ease-in-out;
    height: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.221);
    background: linear-gradient(darken($color-primary, 5%), lighten($color-primary, 5%), darken($color-primary, 5%));
}

.progress80 {
    width: 80%;
}

.progress60 {
    width: 60%;
}

.progress40 {
    width: 40%;
}

.progress20 {
    width: 20%;
}

@keyframes progress {
    0% {
        transform-origin: 0%;
        transform: scaleX(0);
    }
    100% {
        transform-origin: 0% 50%;
        transform: scaleX(1);
        transition: all 500ms 300ms ease-in-out;
    }
}