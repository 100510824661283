@import '../../style/utils.scss';

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    padding: 20px;
    animation: apparition 1s backwards ease-in-out;

    &__content {
        font-size: 1.2em;
        line-height: 2.5em;
        margin: 0;
        margin-top: 90px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 80px;
        z-index: 5;

        @include mobile {
            flex-direction: column;
            gap: 20px;
        }

        img {
            border-radius: 30%;
            border-width: 6px;
            border-style: double;
            border-color: black;
            max-height: 350px;
            min-height: 160px;
            

            @include tablet {
                width: 45%;
            }
            @include mobile {
                width: auto;
                max-height: 30vh;
            }
        }

        &Big {
            font-size: 1.8em;
            font-weight: 400;
            font-family: 'Lobster Two', 'Brush Script MT';
        }
    }

    &__next {
        height: 20%;
        margin-top: 40px;
        z-index: 5;

        p {
            margin: 0;
            font-size: 1em;
            margin-bottom: 10px;
            text-transform: uppercase;
            @include mobile {
                margin-bottom: 10px;
            }
        }

        a > &Arrow {
            opacity: 1;
            transition: opacity 200ms;
            animation: arrowMove 4s 2s ease-in-out infinite both;
        }

        a:hover > &Arrow, a:focus > &Arrow {
            opacity: 0.7;
            transition: opacity 100ms;
            animation-play-state: paused;
        }
    }

    @include tablet {
        font-size: 0.8em;
    }
}

// Animations
@keyframes arrowMove {
    20% {
        transform: translateY(20px);
    }

    40% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes apparition {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}