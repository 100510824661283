@import './utils.scss';
@import  'normalize.css';

/* Balises générales */
* {
    font-family: 'Open Sans', 'Arial';
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

a {
    text-decoration: none;
    color: inherit;
}

strong {
    font-weight: 700;
    font-style: italic;
}

body {
    margin: auto;
    font-weight: 400;
}

section {
    min-height: calc(100vh + 1px);
    position: relative;
}

ul {
    padding: 0;
    margin: 0;
}

li {
    list-style: none;
}

button {
    border: none;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    font-size: 1em;
}

/* Style de la page */
#root {
    margin: 0 auto;
    padding: 0;
    min-height: 100vh;
    background-color: $color-primary;
    overflow: hidden;
}

/* Accessibilité */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}


/* Modale */
.ReactModal__Overlay {
    z-index: 20;
    opacity: 0;
    transition: opacity 500ms ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.ReactModal__Overlay--after-open {
    opacity: 1;
}
  
.ReactModal__Overlay--before-close {
    opacity: 0;
}
  
.ReactModal__Content {
    transform: scale(0);
    transition: transform 500ms ease-in-out;
}
  
.ReactModal__Overlay--after-open .ReactModal__Content {
    transform: scale(1);
}
  
.ReactModal__Overlay--before-close .ReactModal__Content {
    transform: scale(0);
}

.ant-tabs-tab-btn {
    @include mobile {
        font-size: 0.9em;
    }

    &:hover {
    opacity: 0.7;
    }
}

// .css-zvi4ix {
//     width: 70%;
//     max-width: 855px;

//     @include tablet {
//         width: 80%;
//     }

//     @include mobile {
//         width: 100%;
//     }
// }