@import '../../style/utils.scss';

.footer {
    background-color: black;
    padding: 20px;
    position: relative;
    color: white;
    font-size: 0.9em;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;

    @include mobile {
        font-size: 0.8em;
        padding: 15px;
        gap: 8px;
    }

    > p {
        margin: 0;

        i {
            margin-right: 7px;
        }
    }

    &__button {
        opacity: 1;
        transition: opacity 150ms;
        color: white;

        &:hover, &:focus {
            opacity: 0.7;
            transition: opacity 200ms;
        }
    }

    &__modal {
        background: linear-gradient(135deg, $color-primary, lighten($color-primary, 5%), $color-primary);
        width: 90%;
        max-width: 770px;
        max-height: 90vh;
        border-radius: 15px;
        border: none;
        outline: 0;
        @include shadow;
        overflow: auto;

        &Container {
            position: relative;
            padding: 40px;

            @include mobile {
                padding: 15px;
            }
        }

        &Section {
            min-height: min-content;
            margin-top: 20px;
            font-size: 0.9em;

            li {
                margin-bottom: 10px;
            }

            span {
                font-weight: 500;
                margin-right: 10px;
                font-style: oblique;
            }

            h2 {
                text-decoration: underline;
                font-size: 1.1em;
                margin-top: 0;
                margin-bottom: 30px;
            }

            h3 {
                font-size: 1em;
                margin-top: 25px;
            }

            p {
                text-align: justify;
            }
        }

        &CloseBtn {
            position: absolute;
            right: 7px;
            top: 12px;
            color: $color-secondary;
            background-color: white;
            border-radius: 50%;
            padding: 5px;
            transition: all 200ms;

            @include mobile {
                transform: scale(0.9);
            }

            &:hover, &:focus {
                color: white;
                background-color: $color-secondary;
                transition: all 200ms;
            }
        }
    }
}