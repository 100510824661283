@import '../../style/utils.scss';

.section {
    background-color: $color-primary;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 55px 10px;
    transform: translateY(1px);

    &[animation='1'] &__title {
        opacity: 1;
        transition: opacity 700ms 200ms ease-in-out;
    }

    &[animation='1'] &__links {
        opacity: 1;
        transition: opacity 700ms 500ms ease-in-out;
    }

    &[animation='1'] &__lineLinks, &[animation='1'] &__form > div div:nth-child(1) {
        opacity: 1;
        transition: opacity 700ms 800ms ease-in-out;
    }

    &[animation='1'] &__form {
        > div div:nth-child(2) {
            opacity: 1;
            transition: opacity 700ms 1.1s ease-in-out;

            @include tablet {
                transition-delay: 800ms;
            }
        }

        &Submit {
            opacity: 1;
            background-color: white;
            color: $color-secondary;
            transition: opacity 700ms 1.4s ease-in-out, background-color 200ms, color 200ms;

            @include tablet {
                transition: opacity 700ms 1.1s ease-in-out, background-color 200ms, color 200ms;
            }            

            &[disabled] {
                cursor: auto;
                background-color: lighten($color-secondary, 50%);

                &:hover, &:focus, &:active {
                    cursor: auto;
                    background-color: lighten($color-secondary, 50%);
                    color: $color-secondary;
                }
            }

            &:hover, &:focus, &:active {
                background-color: $color-secondary;
                color: white;
                transition: background-color 200ms, color 200ms;
            }
        }
    }

    &__title {
        width: 70%;
        max-width: 855px;
        opacity: 0;

        @include tablet {
            width: 80%;
        }

        @include mobile {
            width: 100%;
        }

        &Line {
            height: 1px;
            background-color: black;
            width: 150px;
            margin: 40px auto 50px auto;
    
            @include mobile {
                margin: 20px auto 35px auto;
            }
        }

        h2 {
            font-family: 'Lobster Two', 'Brush Script MT';
            font-size: 3em;
            text-align: center;
            font-weight: 500;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 40px 0;
            position: relative;

            @include mobile {
                margin: 20px 0;
            }

            span {
                text-transform: uppercase;
                opacity: 0.1;
                font-size: 1.7em;
                position: absolute;

                @include tablet {
                    font-size: 1.5em;
                }
            }
        }
    }

    &__container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 95%;
        height: 235px;
        gap: 30px;

        @include tablet {
            height: auto;
            align-items: normal;
        }

        @include mobile {
            flex-direction: column;
            align-items: center;
            gap: 40px;
            width: 100%;
        }
    }

    &__links {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 30px;
        opacity: 0;

        @include tablet {
            justify-content: space-around;
            gap: 20px;
            height: 337px;
        }

        @include mobile {
            flex-direction: row;
            justify-content: space-around;
            gap: 15px;
            width: 100%;
            height: auto;
        }

        a {
            opacity: 1;
            transform: scale(1.3);
            transition: all 150ms;

            &:hover, &:focus {
                opacity: 0.7;
                transform: scale(1.6);
                transition: all 200ms;
            }

            &.iconePDF {
                transform: scale(1.3) translateX(2px);

                &:hover, &:focus {
                    transform: scale(1.6) translateX(2px);
                }
            }

            @include mobile {
                transform: scale(1.4);

                &.iconePDF {
                    transform: scale(1.3);

                    &:hover, &:focus {
                        transform: scale(1.5);
                    }
                }
            }
        }
    }

    &__lineLinks {
        width: 1px;
        background-color: rgba(0, 0, 0, 0.4);
        height: 100%;
        opacity: 0;

        @include tablet {
            display: none;
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 80%;

        @include tablet {
            gap: 10px;
            width: 100%;
        }

        > div {
            display: flex;
            gap: 20px;
            height: 160px;
            justify-content: center;
            justify-content: space-between;

            @include tablet {
                flex-direction: column;
                gap: 10px;
                height: 337px;
            }

            div {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 48%;
                opacity: 0;

                @include tablet {
                    width: 100%;
                    gap: 10px;
                }
            }
        }

        &Input {
            padding: 5px 10px;
            border: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.401);
            border-left: 7px solid $color-secondary;
            border-radius: 5px;
            height: 40px;

            @include tablet {
                height: auto;
            }

            &:focus {
                background-color: lighten($color-secondary, 60%);
                outline: none;
            }
        }

        &Submit {
            appearance: none;
            width: min-content;
            border-radius: 5px;
            font-weight: 600;
            font-size: 0.9em;
            padding: 5px 15px;
            margin: 15px auto 0 auto;
            border: 1px solid rgba(0, 0, 0, 0.4);
            cursor: pointer;
            opacity: 0;

            @include tablet {
                font-size: 1em;
            }

            @include mobile {
                font-size: 1.1em;
            }
        }

        textarea {
            height: 160px;
            font-family: 'Open Sans', 'Arial';
            padding: 10px;
            border: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.401);
            border-left: 7px solid $color-secondary;
            border-radius: 5px;

            @include tablet {
                height: 220px;
            }

            &:focus {
                background-color: lighten($color-secondary, 60%);
                outline: none;
            }

            &::-moz-placeholder {
                font-size: 0.83em;
            }
        }
    }

    @include tablet {
        font-size: 0.9em;
    }

    @include mobile {
        font-size: 0.8em;
    }
}