@import '../../style/utils.scss';

.angle {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 16vh;
    background-color: $color-primary;
    transform: skewY(-2deg) translateY(8vh);
}

.section {
    background-color: $color-secondary;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &__line {
        height: 1px;
        background-color: $color-primary;
        width: 150px;
        margin: 40px auto 50px auto;

        @include mobile {
            margin: 20px auto 35px auto;
        }
    }

    &__bloc {
        color: white;
        transform: translateY(-4vh);
        padding: calc(4vh + 55px) 10px;
        width: 70%;
        max-width: 855px;
        opacity: 0;
        transform: translateY(100vh);
    
        @include tablet {
            width: 80%;
        }
    
        @include mobile {
            width: 100%;
        }

        &[animation='1'] {
            opacity: 1;
            transform: translateY(0);
            transition: all 1s 100ms ease-in-out;
        }

        h2 {
            font-family: 'Lobster Two', 'Brush Script MT';
            font-size: 3em;
            text-align: center;
            font-weight: 500;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 40px 0;
            position: relative;

            @include mobile {
                margin: 20px 0;
            }

            span {
                text-transform: uppercase;
                opacity: 0.1;
                font-size: 1.7em;
                position: absolute;

                @include tablet {
                    font-size: 1.5em;
                }
            }
        }

        p {
            text-align: justify;
            text-indent: 20px;
            font-size: 1.1em;
            margin: 20px 0;

            em {
                font-style: italic;
                font-weight: 600;
            }

            a {
                padding-bottom: 1px;
                border-bottom: 1px white solid;
                opacity: 1;
                transition: opacity 100ms;

                &:hover, &:focus {
                    opacity: 0.8;
                    transition: opacity 100ms;
                }
            }
        }
    }

    @include tablet {
        font-size: 0.9em;
    }

    @include mobile {
        font-size: 0.8em;
    }
}