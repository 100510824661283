@import '../../style/utils.scss';

$color: white $color-secondary lighten($color-secondary, 30%) black darken($color-primary, 10%);

.loader {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: disparition 500ms 3.5s both ease-in-out;

    &__title {
        font-family: 'Lobster Two', 'Brush Script MT';
        font-weight: 400;
        font-size: 5em;
        margin: 0;
        animation: tracking-in-expand 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) backwards, tracking-out-contract 0.7s 3.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) forwards;

        @include tablet {
            font-size: 4em;
        }

        @include mobile {
            font-size: 2.5em;
        }
    }

    &__line {
        margin: 40px 0;
        height: 3px;
        width: 30%;
        max-width: 350px;
        background-color: black;
        animation: lineScale 1s 1s backwards ease-in-out, lineScaleReverse 0.7s 3.2s forwards ease-in-out;
    }

    &__bubble {
        border-radius: 50%;
        background-color: transparent;
        position: absolute;
        animation: bubble 3s both infinite ease-in-out;

        &:hover {
            animation: jello-horizontal 800ms ease-in-out both;
        }

        @for $i from 1 through 10 {
            &#{$i} {
                width: 5vh * $i;
                height: 5vh * $i;
                $randomTop: random(100);
                $randomLeft: random(100);
                border: 1px solid #{nth($color, if($i > 5, $i - 5, $i))};
                box-shadow: 0 0 40px #{nth($color, if($i > 5, $i - 5, $i))} inset;
                top: percentage(calc($randomTop / 100));
                left: percentage(calc($randomLeft / 100));
                animation-delay: ($i - 1) * 300ms;
            }
        }
    }
}

@keyframes bubble {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2023-9-16 17:16:41
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
 */
@keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }

    40% {
      opacity: 0.6;
    }

    100% {
      opacity: 1;
    }
}

/**
 * ----------------------------------------
 * animation tracking-out-contract
 * ----------------------------------------
 */
@keyframes tracking-out-contract {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    100% {
        letter-spacing: -0.5em;
        opacity: 0;
    }
}
  
@keyframes disparition {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes lineScale {
    0% {
        transform-origin: 0 center;
        transform: scaleX(0);
    }
    100% {
        transform: scaleX(1);
    }
}

@keyframes lineScaleReverse {
    0% {
        transform: scaleX(1);
    }
    100% {
        transform: scaleX(0);
    }
}



@keyframes jello-horizontal {
    0% {
        transform: scale3d(1, 1, 1);
    }
    30% {
        transform: scale3d(1.25, 0.75, 1);
    }
    40% {
        transform: scale3d(0.75, 1.25, 1);
    }
    50% {
        transform: scale3d(1.15, 0.85, 1);
    }
    65% {
        transform: scale3d(0.95, 1.05, 1);
    }
    75% {
        transform: scale3d(1.05, 0.95, 1);
    }
    100% {
        transform: scale3d(1, 1, 1);
    }
}